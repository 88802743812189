<template>
  <div class="commission-list">
    <div class="staff-title-fixed wca-center">员工</div>

    <div ref="titlesRef" class="titles">
      <div class="left wca-center">员工</div>
      <div class="right">
        <div class="main-left wca-center">总提成</div>
        <div class="main-right">
          <div class="top-title">
            <div class="top-title-1">消耗提成</div>
            <div class="top-title-2">销售提成</div>
          </div>
          <div class="center-title">
            <!-- 消耗业绩 -->
            <div>总额</div>
            <div>现金消耗</div>
            <div>余额本金</div>
            <div>余额赠金</div>
            <div>储值卡本金</div>
            <div>储值卡赠金</div>
            <div>次卡本金</div>
            <div>次卡赠送</div>
            <div>兑换券</div>
            <div>产品</div>
            <!-- 销售业绩 -->
            <div>总额</div>
            <div>余额</div>
            <div>储值卡</div>
            <div>次卡</div>
          </div>
        </div>
      </div>
    </div>
    <div class="score-wrap">
      <div @scroll="scroll" class="score-wrap-content">
        <div class="fixed-wrap">
          <div ref="fixedRef" class="fixed">
            <div class="fixed-title">合计</div>
            <template v-for="(item, index) in stafftichengList">
              <div class="score-item">
                <div class="base-info">
                  <div class="avatar">
                    <img :src="item.userPhoto" alt="" />
                  </div>
                  <div class="emp-name">
                    <div class="name">{{ item.userRealname }}</div>
                    <div class="rank">{{ item.levelName }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="total-title">
          <div class="start"></div>
          <div class="main-score">¥ {{ stafftichengTotalInfo.total }}</div>
          <div>¥ {{ stafftichengTotalInfo.xiaohaoTotal }}</div>
          <div>¥ {{ stafftichengTotalInfo.xjxh }}</div>
          <div>¥ {{ stafftichengTotalInfo.yebj }}</div>
          <div>¥ {{ stafftichengTotalInfo.yezj }}</div>
          <div>¥ {{ stafftichengTotalInfo.czkbj }}</div>
          <div>¥ {{ stafftichengTotalInfo.czkzj }}</div>
          <div>¥ {{ stafftichengTotalInfo.ckbj }}</div>
          <div>¥ {{ stafftichengTotalInfo.ckzj }}</div>
          <div>¥ {{ stafftichengTotalInfo.dhq }}</div>
          <div>¥ {{ stafftichengTotalInfo.cp }}</div>
          <div>¥ {{ stafftichengTotalInfo.xiaoshouTotal }}</div>
          <div>¥ {{ stafftichengTotalInfo.ye }}</div>
          <div>¥ {{ stafftichengTotalInfo.czk }}</div>
          <div>¥ {{ stafftichengTotalInfo.ck }}</div>
        </div>
        <div class="staff-score-list">
          <template v-for="item in stafftichengList">
            <div class="score-item">
              <div class="base-info">
                <div class="avatar">
                  <img :src="item.userPhoto" alt="" />
                </div>
                <div class="emp-name">
                  <div class="name">{{ item.userRealname }}</div>
                  <div class="rank">{{ item.levelName }}</div>
                </div>
              </div>
              <div>{{ item.staff_total }}</div>
              <!-- 消耗提成 -->
              <div>{{ item.staff_xhtc }}</div>
              <div>{{ item.xianjin }}</div>
              <div>{{ item.yueBenjin }}</div>
              <div>{{ item.yueZengsong }}</div>
              <div>{{ item.chuzhiBenjin }}</div>
              <div>{{ item.chuzhiZengsong }}</div>
              <div>{{ item.cikaBenjin }}</div>
              <div>{{ item.cikaZengsong }}</div>
              <div>{{ item.duihuan }}</div>
              <div>{{ item.chanpin }}</div>
              <!-- 销售提成 -->
              <div>{{ item.staff_xstc }}</div>
              <div>
                {{ item.yueeSale }}
              </div>
              <div>{{ item.chuzhiSale }}</div>
              <div>{{ item.cikaSale }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.page"
        v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="staffyejiTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store'
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import { getDanbiTc } from '@/service/main/staff'
import { deepClone } from '@/utils/clone'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const titlesRef = ref(null)
    const fixedRef = ref()

    const scroll = e => {
      let left = e.target.scrollLeft
      let top = e.target.scrollTop
      titlesRef.value.style.left = -left + 'px'
      fixedRef.value.style.top = -top + "px"
    }

    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      date: new Date(),
      shopId: '',
      type: 1,
      starttime: "",
      endtime: ""
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const dataList = ref([])
    const dataCount = ref(10)

    const stafftichengList = ref([])
    const staffyejiTotal = ref([])
    const stafftichengTotalInfo = ref([])

    emitter.on('refreshtichengDatetime2', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime2')
    })


    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        queryInfo.shopId = shopId
      }
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      if (start != undefined) {
        queryInfo.starttime = start
        queryInfo.endtime = end
      } else {
        queryInfo.starttime = date + ' 00:00:00'
        queryInfo.endtime = date + ' 23:59:59'
      }
    }
    initData()

    const initPage = async () => {
      let obj = deepClone({ ...queryInfo })
      // delete obj.shopId
      delete obj.date
      delete obj.type
      const res = await getDanbiTc(obj)
      let list = res.data.emps
      let xjxh = 0, yebj = 0, yezj = 0, czkbj = 0, czkzj = 0, ckbj = 0,
        ckzj = 0, dhq = 0, cp = 0, ye = 0, czk = 0, ck = 0;

      list.forEach(item => {
        xjxh += Number(item.xianjin)
        yebj += Number(item.yueBenjin)
        yezj += Number(item.yueZengsong)
        czkbj += Number(item.chuzhiBenjin)
        czkzj += Number(item.chuzhiZengsong)
        ckbj += Number(item.cikaBenjin)
        ckzj += Number(item.cikaZengsong)
        cp += Number(item.chanpin)
        dhq += Number(item.duihuan)

        ye += Number(item.yueeSale)
        czk += Number(item.chuzhiSale)
        ck += Number(item.cikaSale)

        let staff_xhtc = Number(item.xianjin) + Number(item.yueBenjin) + Number(item.yueZengsong) + Number(item.chuzhiBenjin)
          + Number(item.chuzhiZengsong) + Number(item.cikaBenjin) + Number(item.cikaZengsong) + Number(item.chanpin) + Number(item.duihuan)
        let staff_xstc = Number(item.yueeSale) + Number(item.chuzhiSale) + Number(item.cikaSale)
        let staff_total = staff_xhtc + staff_xstc
        item['staff_xhtc'] = staff_xhtc
        item['staff_xstc'] = staff_xstc
        item['staff_total'] = staff_total
        for (let key in item) {
          if (key != 'userRealname' && key != 'levelName' && key != 'userPhoto') {
            item[key] = (Number(item[key]) / 100).toFixed(2)
          }
        }

      })

      let totalInfo = {
        xiaohaoTotal:
          xjxh + yebj + yezj + czkbj + czkzj + ckbj + ckzj + dhq + cp,
        xiaoshouTotal: ye + czk + ck,
        xjxh,
        yebj,
        yezj,
        czkbj,
        czkzj,
        ckbj,
        ckzj,
        dhq,
        cp,
        ye,
        czk,
        ck
      }
      totalInfo['total'] =
        totalInfo.xiaohaoTotal + totalInfo.xiaoshouTotal
      for (let key in totalInfo) {
        totalInfo[key] = (Number(totalInfo[key]) / 100).toFixed(2)
      }

      stafftichengTotalInfo.value = totalInfo
      stafftichengList.value = list
      staffyejiTotal.value = res.data.total

      // store.dispatch('staff/getStaffScoreListAction', { ...queryInfo })
    }
    initPage()


    return {
      scroll,
      titlesRef,
      queryInfo,
      handleSizeChange,
      handleCurrentChange,
      dataList,
      dataCount,
      stafftichengList,
      UPLOAD_BASE_URL,
      staffyejiTotal,
      fixedRef,
      stafftichengTotalInfo
    }

  }
})
</script>

<style scoped lang="less">
.commission-list {
  position: relative;

  .fixed-wrap {
    position: absolute;
    z-index: 8;
    height: 384px;
    overflow: hidden;

    .fixed {
      background-color: white;
      min-width: 180px;
      position: relative;

      .fixed-title {
        line-height: 56px;
        text-align: center;
        background-color: rgb(247, 248, 250);
        font-size: 12px;
      }
    }
  }

  .wca-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e2e1e1;
  }

  .staff-title-fixed {
    position: absolute;
    background-color: rgb(247, 248, 250);
    z-index: 10;
    flex: 1;
    height: 112px;
    min-width: 180px;
    font-size: 12px;
    box-shadow: inset 1px -1px 0 0 #ebedf0;
  }

  .el-pagination {
    z-index: 11;
    position: relative;
    background-color: #fff;
  }

  .score-item {
    display: flex;
    line-height: 56px;
    height: 56px;
    font-size: 12px;

    .base-info {
      min-width: 180px;
      display: flex;
      padding-left: 10px;

      .emp-name {
        height: 56px;
        display: flex;
        flex-direction: column;
        line-height: 20px;
        justify-content: center;
      }

      .avatar {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }

    & > div {
      min-width: 150px;
      border-bottom: 1px solid #e2e1e1;
      border-left: 1px solid #e2e1e1;
      padding-left: 10px;
    }
  }

  .score-wrap {
    height: 400px;
    overflow: hidden;
    z-index: 11;

    .score-wrap-content {
      height: 400px;
      overflow-x: scroll;
    }

    .staff-score-list {
      box-sizing: border-box;

      .score-item:hover div {
        background-color: rgb(246, 244, 255);
      }
    }

    .total-title {
      display: flex;
      font-size: 12px;

      .start {
        min-width: 180px;
        border-top: 1px solid #e2e1e1;
        // padding-left: 10px;
        text-align: center;
      }

      .main-score {
        border-top: 1px solid #e2e1e1;
      }

      & > div {
        min-width: 150px;
        height: 56px;
        line-height: 56px;
        background-color: rgb(247, 248, 250);
        border-left: 1px solid #e2e1e1;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }
  }

  .titles {
    display: flex;
    background-color: rgb(247, 248, 250);
    font-size: 12px;
    position: relative;
    left: 0px;
    // overflow-y: scroll;
    // overflow-x: scroll;
    // margin-bottom: -15px;
    // padding-bottom: 15px;

    .wca-center {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e2e1e1;
    }

    .right {
      flex: 9;
      display: flex;
      background-color: rgb(247, 248, 250);

      .main-right {
        flex: 9;
        display: flex;
        flex-direction: column;

        .top-title {
          // height: 56px;
          border-left: 1px solid #e2e1e1;
          // text-align: center;
          // line-height: 56px;
          display: flex;

          & > div {
            line-height: 56px;
            text-align: center;
            border-right: 1px solid #e2e1e1;
          }

          .top-title-1 {
            flex: 10;
          }

          .top-title-2 {
            flex: 4;
          }
        }

        .center-title {
          display: flex;
          border-top: 1px solid #e2e1e1;

          & > div {
            flex: 1;
            height: 56px;
            border-left: 1px solid #e2e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #e2e1e1;
            border-bottom: 1px solid #e2e1e1;
            min-width: 150px;
          }
        }

        .top-title {
        }
      }

      .main-left {
        flex: 1;
        min-width: 150px;
      }
    }

    .left {
      flex: 1;
      height: 112px;
      min-width: 180px;
      background-color: rgb(247, 248, 250);
    }
  }
}
</style>
