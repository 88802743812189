export const contentTableConfig = {
  propList: [
    {
      prop: 'orderNo',
      label: '订单号',
      minWidth: '220',
      slotName: 'orderNo',
      fixed: true
    },
    { prop: 'itemName', label: '商品名称', minWidth: '100', slotName: 'itemName',  fixed: true },
    {
      prop: 'shopName',
      label: '门店名称',
      minWidth: '200',
      slotName: 'shopName'
    },
    {
      prop: 'orderFinishTime',
      label: '完成时间',
      minWidth: '120',
      slotName: 'orderFinishTime'
    },
    {
      prop: 'salePrice',
      label: '原价',
      minWidth: '120',
      slotName: 'salePrice'
    },
    {
      prop: 'qty',
      label: '数量',
      minWidth: '120',
      slotName: 'qty'
    },
    {
      prop: 'promPrice',
      label: '优惠',
      minWidth: '120',
      slotName: 'promPrice'
    },
    {
      prop: 'payKind',
      label: '支付方式',
      minWidth: '120',
      slotName: 'payKind'
    },
    {
      prop: 'orderKind',
      label: '订单类型',
      minWidth: '120',
      slotName: 'orderKind'
    },
    {
      prop: 'totalPrice',
      label: '实付',
      minWidth: '120',
      slotName: 'totalPrice'
    },
    {
      prop: 'userRealname',
      label: '员工',
      minWidth: '150',
      slotName: 'userRealname'
    },
    {
      prop: 'xsyj',
      label: '销售业绩',
      minWidth: '120',
      slotName: 'xsyj'
    },
    {
      prop: 'xstc',
      label: '销售提成',
      minWidth: '120',
      slotName: 'xstc'
    },
    {
      prop: 'xhyj',
      label: '消耗业绩',
      minWidth: '120',
      slotName: 'xhyj'
    },
    {
      prop: 'xhtc',
      label: '消耗提成',
      minWidth: '120',
      slotName: 'xhtc'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

export const contentTableConfig2 = {
  propList: [
    { prop: 'userRealname', label: '状态', minWidth: '150', slotName: 'userRealname' },
    { prop: 'userMobile', label: '交易内容', minWidth: '150' },
    {
      prop: 'levelName',
      label: '销售业绩',
      minWidth: '100',
      slotName: 'office'
    },
    {
      prop: 'dpName',
      label: '销售提成',
      minWidth: '100',
      slotName: 'dpName'
    },
    {
      prop: 'joinTime',
      label: '劳动业绩',
      minWidth: '120',
      slotName: 'joinTime'
    },


    {
      prop: 'joinTime',
      label: '劳动提成',
      minWidth: '120',
      slotName: 'joinTime'
    },
    {
      prop: 'joinTime',
      label: '生成时间',
      minWidth: '120',
      slotName: 'joinTime'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
