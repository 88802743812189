<template>
  <div class="commission-list">
    <div ref="titlesRef" class="titles">
      <div class="left wca-center">员工</div>
      <div class="right">
        <div class="main-left wca-center">总提成</div>
        <div class="main-right">
          <div>月份</div>
          <div>更新时间</div>
          <div>消耗综合提成</div>
          <div>销售综合提成</div>
        </div>
      </div>
    </div>
    <div class="score-wrap">
      <div @scroll="scroll" class="score-wrap-content">
        <div class="fixed-wrap">
          <div ref="fixedRef" class="fixed">
            <div class="fixed-title">合计</div>
            <template v-for="(item, index) in zonghetichengList">
              <div class="score-item">
                <div class="base-info">
                  <div class="avatar">
                    <img :src="item.userPhoto" alt="" />
                  </div>
                  <div class="emp-name">
                    <div class="name">{{ item.userRealname }}</div>
                    <div class="rank">{{ item.levelName }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="total-title">
          <div class="start">合计</div>
          <div class="main-score">¥ {{ zonghetichengTotalInfo.sum }}</div>
          <div class="item">{{ currentMonth }}月</div>
          <div class="item">-</div>
          <div class="item">¥ {{ zonghetichengTotalInfo.xh }}</div>
          <div class="item">¥ {{ zonghetichengTotalInfo.xs }}</div>
        </div>
        <div class="staff-score-list">
          <template v-for="item in zonghetichengList">
            <div class="score-item">
              <div class="base-info">
                <div class="avatar">
                  <img :src="item.userPhoto" alt="" />
                </div>
                <div class="emp-name">
                  <div class="name">{{ item.userRealname }}</div>
                  <div class="rank">{{ item.levelName }}</div>
                </div>
              </div>
              <div>¥ {{ item.totalTicheng }}</div>
              <div class="item">{{ currentMonth }}月</div>
              <div class="item">-</div>
              <div class="item">¥ {{ item.serveTicheng }}</div>
              <div class="item">¥ {{ item.saleTicheng }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.page"
        v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="zonghetichengTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { UPLOAD_BASE_URL } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import { getShopTcYj } from '@/service/main/staff'
import { deepClone } from '@/utils/clone'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const store = useStore()

    const titlesRef = ref(null)
    const scroll = e => {
      let left = e.target.scrollLeft
      let top = e.target.scrollTop
      titlesRef.value.style.left = -left + 'px'
      fixedRef.value.style.top = -top + "px"
      // console.log(left);

    }

    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      date: new Date(),
      shopId: '',
      type: 1,
      starttime: "",
      endtime: ""
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const fixedRef = ref()

    const dataList = ref([])
    const dataCount = ref(10)

    const zonghetichengList = ref([])
    const zonghetichengTotal = ref(0)
    const zonghetichengTotalInfo = ref([])

    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        queryInfo.shopId = shopId
      }
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')

      if (start != undefined) {
        let date = dayjs(start).format('YYYY-MM')
        let monthTotal = dayjs(start).daysInMonth()

        // queryInfo.starttime = date + '-' + '01 00:00:00'
        // queryInfo.endtime = date + '-' + monthTotal + ' 23:59:59'
        queryInfo.starttime = start
        queryInfo.endtime = end

      } else {
        let date = dayjs(new Date()).format('YYYY-MM')
        let monthTotal = dayjs(new Date()).daysInMonth()

        queryInfo.starttime = date + '-' + '01 00:00:00'
        queryInfo.endtime = date + '-' + monthTotal + ' 23:59:59'
      }
    }
    initData()

    const currentMonth=ref('')
    const initPage = async () => {
      let month= dayjs(queryInfo.starttime).format("MM")
      currentMonth.value=month

      let obj = deepClone({ ...queryInfo })
      // delete obj.shopId
      delete obj.date
      delete obj.type
      const res = await getShopTcYj(obj)
      let list = res.data.list
      let xh = 0, xs = 0, sum = 0

      list.forEach(item => {
        xh += Number(item.serveTicheng)
        xs += Number(item.saleTicheng)
        sum += Number(item.totalTicheng)
        for (let key in item) {
          if (key != 'userRealname' && key != 'userPhoto') {
            item[key] = (Number(item[key]) / 100).toFixed(2)
          }
        }
      })
      let totalInfo = {
        xh,
        xs,
        sum
      }
      for (let key in totalInfo) {
        totalInfo[key] = (Number(totalInfo[key]) / 100).toFixed(2)
      }
      zonghetichengTotalInfo.value = totalInfo
      zonghetichengList.value = list
      zonghetichengTotal.value = res.data.total
      // store.dispatch('staff/getStaffzongheyejiAction', { ...queryInfo })
    }
    initPage()

    emitter.on('refreshtichengDatetime4', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime4')
    })

    return {
      currentMonth,
      scroll,
      titlesRef,
      queryInfo,
      handleSizeChange,
      handleCurrentChange,
      dataList,
      dataCount,
      fixedRef,
      zonghetichengList,
      UPLOAD_BASE_URL,
      zonghetichengTotal,
      zonghetichengTotalInfo,
    }

  }
})
</script>

<style scoped lang="less">
.commission-list {
  position: relative;

  div {
    // box-sizing: border-box;
  }

  .wca-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e2e1e1;
  }

  .el-pagination {
    z-index: 11;
    position: relative;
    background-color: #fff;
  }

  .fixed-wrap {
    position: absolute;
    z-index: 8;
    height: 384px;
    overflow: hidden;

    .fixed {
      background-color: white;
      min-width: 180px;
      position: relative;

      .fixed-title {
        line-height: 56px;
        text-align: center;
        background-color: rgb(247, 248, 250);
        font-size: 12px;
      }
    }
  }

  .score-item {
    display: flex;
    line-height: 56px;
    height: 56px;
    font-size: 12px;

    .item {
      flex: 1;
    }

    .base-info {
      min-width: 180px;
      display: flex;
      padding-left: 10px;

      .emp-name {
        height: 56px;
        display: flex;
        flex-direction: column;
        line-height: 20px;
        justify-content: center;
      }

      .avatar {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }

    & > div {
      min-width: 150px;
      border-bottom: 1px solid #e2e1e1;
      border-left: 1px solid #e2e1e1;
      padding-left: 10px;
    }
  }

  .score-wrap {
    height: 400px;
    overflow: hidden;
    z-index: 11;

    .score-wrap-content {
      height: 400px;
      overflow-x: scroll;
      // z-index: 10;
    }

    .staff-score-list {
      box-sizing: border-box;

      .score-item:hover div {
        background-color: rgb(246, 244, 255);
      }
    }

    .total-title {
      display: flex;
      font-size: 12px;
      width: 100%;

      .item {
        flex: 1;
        border-top: 1px solid #e2e1e1;
      }

      .start {
        min-width: 180px;
        border-top: 1px solid #e2e1e1;
        padding-left: 10px;
        text-align: center;
      }

      .main-score {
        border-top: 1px solid #e2e1e1;
      }

      & > div {
        min-width: 150px;
        height: 56px;
        line-height: 56px;
        background-color: rgb(247, 248, 250);
        border-left: 1px solid #e2e1e1;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }
  }

  .titles {
    display: flex;
    background-color: rgb(247, 248, 250);
    font-size: 12px;
    position: relative;
    left: 0px;
    padding-right: 16px;

    .right {
      flex: 9;
      display: flex;
      background-color: rgb(247, 248, 250);

      .main-right {
        flex: 9;
        display: flex;

        & > div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid #e2e1e1;
        }
      }

      .main-left {
        // flex: 1;
        min-width: 150px;
      }
    }

    .left {
      z-index: 9;
      flex: 1;
      height: 112px;
      min-width: 180px;
      background-color: rgb(247, 248, 250);
    }
  }
}
</style>
