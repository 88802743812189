<template>
  <div class="commission-list">
    <div class="staff-title-fixed wca-center">员工</div>
    <div ref="titlesRef" class="titles">
      <div class="left wca-center">员工</div>
      <div class="right">
        <div class="main-left wca-center">总业绩</div>
        <div class="main-right">
          <div class="top-title">
            <div class="top-title-1">消耗业绩</div>
            <div class="top-title-2">销售业绩</div>
            <div class="top-title-3">现金流水</div>
          </div>
          <div class="center-title">
            <!-- 消耗业绩 -->
            <div>总额</div>
            <div>现金消耗</div>
            <div>余额本金</div>
            <div>余额赠金</div>
            <div>储值卡本金</div>
            <div>储值卡赠金</div>
            <div>次卡本金</div>
            <div>次卡赠送</div>
            <div>兑换券</div>
            <div>产品</div>
            <!-- 销售业绩 -->
            <div>总额</div>
            <div>余额</div>
            <div>储值卡</div>
            <div>次卡</div>
            <!-- 现金流水 -->
            <div>总额</div>
            <div>余额</div>
            <div>储值卡</div>
            <div>次卡</div>
            <div>现金消耗</div>
            <div>产品</div>
          </div>
        </div>
      </div>
    </div>
    <div class="score-wrap">
      <div @scroll="scroll" class="score-wrap-content">
        <div class="fixed-wrap">
          <div ref="fixedRef" class="fixed">
            <div class="fixed-title">合计</div>
            <template v-for="(item, index) in staffyejiList">
              <div class="score-item">
                <div class="base-info">
                  <div class="avatar">
                    <img :src="item.userPhoto" alt="" />
                  </div>
                  <div class="emp-name">
                    <div class="name">{{ item.userRealname }}</div>
                    <div class="rank">{{ item.levelName }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="total-title">
          <div class="start">合计</div>
          <div class="main-score">¥ {{ staffyejiTotalInfo.total }}</div>
          <div>¥ {{ staffyejiTotalInfo.xiaohaoTotal }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjxh }}</div>
          <div>¥ {{ staffyejiTotalInfo.yebj }}</div>
          <div>¥ {{ staffyejiTotalInfo.yezj }}</div>
          <div>¥ {{ staffyejiTotalInfo.czkbj }}</div>
          <div>¥ {{ staffyejiTotalInfo.czkzj }}</div>
          <div>¥ {{ staffyejiTotalInfo.ckbj }}</div>
          <div>¥ {{ staffyejiTotalInfo.ckzj }}</div>
          <div>¥ {{ staffyejiTotalInfo.dhq }}</div>
          <div>¥ {{ staffyejiTotalInfo.cp }}</div>
          <div>¥ {{ staffyejiTotalInfo.xiaoshouTotal }}</div>
          <div>¥ {{ staffyejiTotalInfo.ye }}</div>
          <div>¥ {{ staffyejiTotalInfo.czk }}</div>
          <div>¥ {{ staffyejiTotalInfo.ck }}</div>
          <div>¥ {{ staffyejiTotalInfo.xianjinTotal }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjlsye }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjlsczk }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjlsck }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjlsxjxh }}</div>
          <div>¥ {{ staffyejiTotalInfo.xjlscp }}</div>
        </div>
        <div class="staff-score-list">
          <template v-for="item in staffyejiList">
            <div class="score-item">
              <div class="base-info">
                <div class="avatar">
                  <img :src="item.userPhoto" alt="" />
                </div>
                <div class="emp-name">
                  <div class="name">{{ item.userRealname }}</div>
                  <div class="rank">{{ item.levelName }}</div>
                </div>
              </div>
              <div>¥ {{ item.totalYeji }}</div>
              <!-- 消耗业绩 -->
              <div>¥ {{ item.xhTotal }}</div>
              <div>¥ {{ item.xianjin }}</div>
              <div>¥ {{ item.yueB }}</div>
              <div>¥ {{ item.yueZ }}</div>
              <div>¥ {{ item.czkB }}</div>
              <div>¥ {{ item.czkZ }}</div>
              <div>¥ {{ item.cikB }}</div>
              <div>¥ {{ item.cikZ }}</div>
              <div>¥ {{ item.coupon }}</div>
              <div>¥ {{ item.goods }}</div>

              <!-- 销售业绩 -->
              <div>¥ {{ item.xsTotal }}</div>
              <div>{{ (Number(item.yueBSale) + Number(item.yueZSale)).toFixed(2) }}</div>
              <div>¥ {{ item.czkSale }}</div>
              <div>¥ {{ item.cikSale }}</div>
              <!-- 现金流水 -->
              <div>¥ {{ item.xjlsTotal }}</div>
              <div>¥ {{ item.cashtoYue }}</div>
              <div>¥ {{ item.cashtoCzk }}</div>
              <div>¥ {{ item.cashtoCik }}</div>
              <div>¥ {{ item.cashtoServe }}</div>
              <div>¥ {{ item.cashtoGood }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.page"
        v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="staffyejiTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { UPLOAD_BASE_URL } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const store = useStore()

    const titlesRef = ref(null)
    const scroll = e => {
      let left = e.target.scrollLeft
      let top = e.target.scrollTop
      titlesRef.value.style.left = -left + 'px'
      fixedRef.value.style.top = -top + "px"
    }

    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      date: new Date(),
      shopId: '',
      type: 0,
      starttime: "",
      endtime: ""
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const fixedRef = ref()

    const dataList = ref([])
    const dataCount = ref(10)

    const staffyejiList = computed(() => store.state.staff.staffyejiList)
    const staffyejiTotal = computed(() => store.state.staff.staffyejiTotal)
    const staffyejiTotalInfo= computed(() => store.state.staff.staffyejiTotalInfo)

    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        queryInfo.shopId = shopId
      }
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      if (start != undefined) {
        queryInfo.starttime = start
        queryInfo.endtime = end
      } else {
        queryInfo.starttime = date + ' 00:00:00'
        queryInfo.endtime = date + ' 23:59:59'
      }
    }
    initData()

    const initPage = () => {
      // console.log({ ...queryInfo });
      store.dispatch('staff/getStaffScoreListAction', { ...queryInfo })
    }
    initPage()

    emitter.on('refreshtichengDatetime1', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime1')
    })

    return {
      scroll,
      titlesRef,
      queryInfo,
      handleSizeChange,
      handleCurrentChange,
      dataList,
      dataCount,
      fixedRef,
      staffyejiList,
      UPLOAD_BASE_URL,
      staffyejiTotal,
      staffyejiTotalInfo
    }

  }
})
</script>

<style scoped lang="less">
.commission-list {
  position: relative;

  .wca-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e2e1e1;
  }

  .staff-title-fixed {
    position: absolute;
    background-color: rgb(247, 248, 250);
    z-index: 10;
    flex: 1;
    height: 112px;
    min-width: 180px;
    font-size: 12px;
    box-shadow: inset 1px -1px 0 0 #ebedf0;
  }

  .el-pagination {
    z-index: 11;
    position: relative;
    background-color: #fff;
  }

  .fixed-wrap {
    position: absolute;
    z-index: 8;
    height: 384px;
    overflow: hidden;

    .fixed {
      background-color: white;
      min-width: 180px;
      position: relative;

      .fixed-title {
        line-height: 56px;
        text-align: center;
        background-color: rgb(247, 248, 250);
        font-size: 12px;
      }
    }
  }

  .score-item {
    display: flex;
    line-height: 56px;
    height: 56px;
    font-size: 12px;

    .base-info {
      min-width: 180px;
      display: flex;
      padding-left: 10px;

      .emp-name {
        height: 56px;
        display: flex;
        flex-direction: column;
        line-height: 20px;
        justify-content: center;
      }

      .avatar {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }

    & > div {
      min-width: 150px;
      border-bottom: 1px solid #e2e1e1;
      border-left: 1px solid #e2e1e1;
      padding-left: 10px;
    }
  }

  .score-wrap {
    height: 400px;
    overflow: hidden;
    z-index: 11;

    .score-wrap-content {
      height: 400px;
      overflow-x: scroll;
    }

    .staff-score-list {
      box-sizing: border-box;

      .score-item:hover div {
        background-color: rgb(246, 244, 255);
      }
    }

    .total-title {
      display: flex;
      font-size: 12px;

      .start {
        min-width: 180px;
        border-top: 1px solid #e2e1e1;
        // padding-left: 10px;
        text-align: center;
      }

      .main-score {
        border-top: 1px solid #e2e1e1;
      }

      & > div {
        min-width: 150px;
        height: 56px;
        line-height: 56px;
        background-color: rgb(247, 248, 250);
        border-left: 1px solid #e2e1e1;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }
  }

  .titles {
    display: flex;
    background-color: rgb(247, 248, 250);
    font-size: 12px;
    position: relative;
    left: 0px;
    //  z-index: 9;

    .right {
      flex: 9;
      display: flex;
      background-color: rgb(247, 248, 250);

      .main-right {
        flex: 9;
        display: flex;
        flex-direction: column;

        .top-title {
          // height: 56px;
          border-left: 1px solid #e2e1e1;
          // text-align: center;
          // line-height: 56px;
          display: flex;

          & > div {
            line-height: 56px;
            text-align: center;
            border-right: 1px solid #e2e1e1;
          }

          .top-title-1 {
            flex: 10;
          }

          .top-title-2 {
            flex: 4;
          }

          .top-title-3 {
            flex: 6;
          }
        }

        .center-title {
          display: flex;
          border-top: 1px solid #e2e1e1;

          & > div {
            flex: 1;
            height: 56px;
            border-left: 1px solid #e2e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #e2e1e1;
            border-bottom: 1px solid #e2e1e1;
            min-width: 150px;
          }
        }

        .top-title {
        }
      }

      .main-left {
        flex: 1;
        min-width: 150px;
      }
    }

    .left {
      z-index: 9;
      flex: 1;
      height: 112px;
      min-width: 180px;
      background-color: rgb(247, 248, 250);
    }
  }
}
</style>
