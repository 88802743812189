<template>
  <div class="commission">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="commission-content">
      <topContent></topContent>
      <el-tabs v-model="activeName" type="card" class="tabs">
        <el-tab-pane label="个人业绩汇总" name="a">
          <danbiYeji></danbiYeji>
        </el-tab-pane>
        <el-tab-pane label="个人提成汇总" name="b">
          <danbiTicheng></danbiTicheng>
        </el-tab-pane>
        <el-tab-pane label="门店业绩汇总" name="c">
          <zongheYeji></zongheYeji>
        </el-tab-pane>
        <el-tab-pane label="门店提成汇总" name="d">
          <zongheTicheng></zongheTicheng>
        </el-tab-pane>
        <el-tab-pane label="开单明细" name="e">
          <yejiMingxi></yejiMingxi>
        </el-tab-pane>
        <el-tab-pane label="退款明细" name="f">
          <refoundList></refoundList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
import danbiYeji from './cpns/danbi-yeji/danbi-yeji.vue'
import danbiTicheng from './cpns/danbi-ticheng/danbi-ticheng.vue'
import zongheYeji from './cpns/zonghe-yeji/zonghe-yeji.vue'
import zongheTicheng from './cpns/zonghe-ticheng/zonghe-ticheng.vue'
import yejiMingxi from './cpns/yeji-mingxi/yeji-mingxi.vue'
import dayjs from 'dayjs'
import test from './cpns/test/test.vue'
import refoundList from './cpns/refund-list/refund-list.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    topContent,
    danbiYeji,
    danbiTicheng,
    yejiMingxi,
    test,
    zongheYeji,
    zongheTicheng,
    refoundList
  },
  setup() {
    const activeName = ref('a')
    const queryInfo = reactive({
      date: new Date(),

    })


    const initPage = () => {
      store.dispatch('staff/getStaffScoreListAction', { ...queryInfo })
    }

    return {
      breadcrumbList,
      activeName,

    }

  }
})
</script>

<style scoped lang="less">
.commission-content {
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  min-width: 1080px;

  &:deep(.el-tabs__item) {
    background-color: rgb(229, 229, 229);
  }

  &:deep(.el-tabs__item.is-active) {
    color: rgba(80, 80, 80, 1) !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(228, 231, 237, 1);
    background-color: #fff;
  }

  &:deep(.tabs) {
    margin-top: 20px;
  }
}
</style>
