export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '退款业绩',
    path: ''
  }
]


export const contentTableConfig = {
  propList: [
    { prop: 'orderNo', label: '订单编号', minWidth: '180' , slotName: 'orderNo'},
    {
      prop: 'suject',
      label: '商品名称',
      minWidth: '200',
      slotName: 'suject'
    },
    {
      prop: 'shopName',
      label: '门店名称',
      minWidth: '120',
      slotName: 'shopName'
    },
    {
      prop: 'createdAt',
      label: '完成时间',
      minWidth: '150',
      slotName: 'createdAt'
    },
    // {
    //   prop: 'isBuy',
    //   label: '最近更新时间',
    //   minWidth: '120',
    //   slotName: 'is_buy'
    // },
    {
      prop: 'amount',
      label: '原价',
      minWidth: '120',
      slotName: 'amount'
    },
    {
      prop: 'isBuy',
      label: '数量',
      minWidth: '120',
      slotName: 'is_buy'
    },
    {
      prop: 'promPrice',
      label: '优惠',
      minWidth: '120',
      slotName: 'promPrice'
    },
    {
      prop: 'payPrice',
      label: '实付',
      minWidth: '120',
      slotName: 'payPrice'
    },
    {
      prop: 'userRealname',
      label: '员工',
      minWidth: '120',
      slotName: 'userRealname'
    },
    {
      prop: 'xsyj',
      label: '销售业绩',
      minWidth: '120',
      slotName: 'xsyj'
    },
    {
      prop: 'xstc',
      label: '销售提成',
      minWidth: '120',
      slotName: 'xstc'
    },
    {
      prop: 'xhyj',
      label: '消耗业绩',
      minWidth: '120',
      slotName: 'xhyj'
    },
    {
      prop: 'xhtc',
      label: '消耗提成',
      minWidth: '120',
      slotName: 'xhtc'
    },
    // { label: '操作', minWidth: '200', slotName: 'handler', }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}

export const timeType=[
  {
    label:'日报',
    value:0,
  }, {
    label:'月报',
    value:1,
  }
]
