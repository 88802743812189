<template>
  <div class="refund-list">
    <page-content :dataList="refoundList" @selectionChange="selectionChange" :contentTableConfig="contentTableConfig"
      :isButtonStyle="true" @tableCellClick="handleTableCellClick">
      <template #amount="scope"> ¥ {{ (scope.row.amount / 100).toFixed(2) }} </template>
      <template #promPrice="scope">
        ¥ {{ ((scope.row.promPrice) / 100).toFixed(2) }}
      </template>

      <template #xsyj="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xsyj == 0">
          ¥ 0.00
        </template>
        <template v-else>
          ¥ {{ (scope.row.xsyj / 100).toFixed(2) }}
        </template>
      </template>
      <template #payPrice="scope">
        ¥ {{ ((scope.row.payPrice) / 100).toFixed(2) }}
      </template>
      <template #xstc="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xstc == 0">
          ¥ 0.00
        </template>
        <template v-else>
          ¥ {{ (scope.row.xstc / 100).toFixed(2) }}
        </template>
      </template>

      <template #xhyj="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xhyj > 0">
          ¥ {{ (scope.row.xhyj / 100).toFixed(2) }}
        </template>
        <template v-else>
          ¥ 0.00
        </template>
      </template>

      <template #xhtc="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xhtc > 0">
          ¥ {{ (scope.row.xhtc / 100).toFixed(2) }}
        </template>
        <template v-else>
          ¥ 0.00
        </template>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="ruleForm.page" v-model:page-size="ruleForm.pagesize"
          :page-sizes="[10, 20, 30, 40]" :small="small" layout="total, sizes, prev, pager, next, jumper"
          :total="refoundListCount" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { useStore } from 'vuex'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    PageContent,
  },
  setup() {
    const store = useStore()
    const dataList = ref([])
    const refoundList = computed(() => store.state.order.refoundList)
    const refoundListCount = computed(() => store.state.order.refoundListCount)

    const ruleForm = reactive({
      shopId: "0",
      keyword: "",
      starttime: '',
      endtime: "",
      source: '0',
      page: 1,
      pagesize: 10
    })

    emitter.on('refreshtichengDatetime7', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime7')
    })
    const handleSizeChange = pagesize => {
      ruleForm.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      ruleForm.page = page
      initPage()
    }

    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        ruleForm.shopId = shopId
      }
      let start = cache.getCache('tichengStart')

      if (start != undefined) {
        let date = dayjs(start).format('YYYY-MM')
        let monthTotal = dayjs(start).daysInMonth()

        ruleForm.starttime = date + '-' + '01 00:00:00'
        ruleForm.endtime = date + '-' + monthTotal + ' 23:59:59'

      } else {
        let date = dayjs(new Date()).format('YYYY-MM')
        let monthTotal = dayjs(new Date()).daysInMonth()

        ruleForm.starttime = date + '-' + '01 00:00:00'
        ruleForm.endtime = date + '-' + monthTotal + ' 23:59:59'
      }
    }
    initData()


    const initPage = async () => {
      store.dispatch('order/getRefoundListAction', { ...ruleForm })
    }
    initPage()

    return {
      breadcrumbList,
      contentTableConfig,
      handleSizeChange,
      handleCurrentChange,
      dataList,
      refoundList,
      refoundListCount,
      ruleForm

    }
  }
})
</script>

<style scoped lang="less">
.refund-list {}
</style>
