<template>
  <div class="kaidan">
    <page-content :dataList="dataList" class="page-content" :contentTableConfig="contentTableConfig"
      @tableCellClick="handleTableCellClick">
      <template #handler>
        <div>

        </div>
      </template>
      <template #itemName="scope">
        {{ scope.row.orderSku[0].itemName }}
      </template>
      <template #qty="scope">
        {{ scope.row.orderSku[0].qty }}
      </template>
      <template #promPrice="scope">
        ¥ {{ ((scope.row.promPrice) / 100).toFixed(2) }}
      </template>
      <template #payKind="scope">
        <!-- <p v-if="scope.row.payKind==1">在线支付直接支付</p>
        <p v-if="scope.row.payKind==2">组合支付</p>
        <p v-if="scope.row.payKind==3">有限次卡支付</p>
        <p v-if="scope.row.payKind==4">无限次卡支付</p>
        <p v-if="scope.row.payKind==6">账户余额支付</p>
        <p v-if="scope.row.payKind==7">储值卡支付</p>
        <p v-if="scope.row.payKind==8">现金</p> -->
        <p>{{scope.row.payWay}}</p>
      </template>
      <template #totalPrice="scope">
        ¥ {{ ((scope.row.totalPrice) / 100).toFixed(2) }}
      </template>
      <template #salePrice="scope">
        ¥ {{ ((scope.row.orderSku[0].salePrice) / 100).toFixed(2) }}
      </template>

      <template #xsyj="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xsyj == 0">
          ¥ 0.00
        </template>
        <template v-else>
          ¥ {{ ((scope.row.xsyj / 100) || 0).toFixed(2) }}
        </template>
      </template>

      <template #xstc="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xstc == 0">
          ¥ 0.00
        </template>
        <template v-else>
          ¥ {{ ((scope.row.xstc / 100) || 0).toFixed(2) }}
        </template>
      </template>

      <template #xhyj="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xhyj > 0">
          ¥ {{ (scope.row.xhyj / 100).toFixed(2) }}
        </template>
        <template v-else>
          ¥ 0.00
        </template>
      </template>

      <template #xhtc="scope">
        <template v-if="scope.row.orderKind == 1 || scope.row.orderKind == 2 || scope.row.xhtc > 0">
          ¥ {{ (scope.row.xhtc / 100).toFixed(2) }}
        </template>
        <template v-else>
          ¥ 0.00
        </template>
      </template>
      <template #orderKind="scope">
        <template v-if="scope.row.orderKind == 1">
          服务订单
        </template>
        <template v-else>
          卡项订单
        </template>
      </template>


    </page-content>
    <div class="el-pagination">
      <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
import { getStaffkaidanyeji } from '@/service/main/staff'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {

    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      shopId: "0",
      starttime: "",
      endtime: "",
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const dataList = ref([])
    const dataCount = ref(10)

    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        queryInfo.shopId = shopId
      }
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      if (start != undefined) {
        queryInfo.starttime = start
        queryInfo.endtime = end
      } else {
        queryInfo.starttime = date + ' 00:00:00'
        queryInfo.endtime = date + ' 23:59:59'
      }
    }
    initData()


    const initPage = async () => {
      const res = await getStaffkaidanyeji({ ...queryInfo })
      // console.log(res);
      dataList.value = res.data.list
      dataCount.value = res.data.total

    }
    initPage()

    emitter.on('refreshtichengDatetime5', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime5')
    })

    return {
      contentTableConfig,
      queryInfo,
      handleSizeChange,
      handleCurrentChange,
      dataList,
      dataCount
    }

  }
})
</script>

<style scoped lang="less">
.kaidan {}
</style>




