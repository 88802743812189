<template>
  <div class="yeji-mingxi">
    <!-- <div class="detail">
      <div class="item">
        <div class="key">销售业绩（元）</div>
        <div class="value">{{ scoreDetail && scoreDetail.saleYeji.toFixed(2) }}</div>
      </div>
      <div class="item">
        <div class="key">销售提成（元）</div>
        <div class="value">{{ scoreDetail && scoreDetail.saleTicheng.toFixed(2) }}</div>
      </div>
      <div class="item">
        <div class="key">劳动业绩（元）</div>
        <div class="value">{{ scoreDetail && scoreDetail.serveYeji.toFixed(2) }}</div>
      </div>
      <div class="item">
        <div class="key">劳动提成（元）</div>
        <div class="value">{{ scoreDetail && scoreDetail.serveTicheng.toFixed(2) }}</div>
      </div>
    </div> -->

    <!-- <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
      <el-tab-pane label="开单业绩" name="first">
        <kaidan></kaidan>
      </el-tab-pane>
      <el-tab-pane label="退款业绩" name="second">
        <tuikuan></tuikuan>
      </el-tab-pane>
    </el-tabs> -->
    <kaidan></kaidan>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import kaidan from './cpns/kaidan/kaidan.vue'
import { getStaffyejimignxi } from '@/service/main/staff'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {
    kaidan,
  },
  setup() {

    const activeName = ref('first')
    const queryInfo = reactive({
      shopId: '',
      starttime: "",
      endtime: "",
    })

    const initData = () => {
      let shopId = cache.getCache('tichengShopid')
      if (shopId != undefined) {
        queryInfo.shopId = shopId
      }
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      if (start != undefined) {
        queryInfo.starttime = start
        queryInfo.endtime = end
      } else {
        queryInfo.starttime = date + ' 00:00:00'
        queryInfo.endtime = date + ' 23:59:59'
      }
    }
    initData()


    const scoreDetail = ref()

    emitter.on('refreshtichengDatetime6', () => {
      initData()
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshtichengDatetime6')
    })


    const initPage = async () => {
      let shopId = cache.getCache('tichengShopid')
      queryInfo.shopId = shopId
      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      if (start != undefined) {
        queryInfo.starttime = start
        queryInfo.endtime = end
      } else {
        queryInfo.starttime = date + ' 00:00:00'
        queryInfo.endtime = date + ' 23:59:59'
      }
      const res = await getStaffyejimignxi({ ...queryInfo })
      // console.log(res);
      scoreDetail.value = res.data

    }
    initPage()

    return {
      activeName,
      scoreDetail
    }

  }
})
</script>

<style scoped lang="less">
.yeji-mingxi {
  &:deep(.tabs) {
    margin-top: 20px;

    .el-tabs__item {
      background-color: transparent;
    }
  }


  .detail {
    display: flex;
    background-color: rgb(247, 248, 250);
    height: 120px;

    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .key {
        font-size: 14px;
      }

      .value {
        font-size: 32px;
        margin-top: 12px;
      }
    }

  }
}
</style>




