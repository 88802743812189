<template>
  <div class="top-content">
    <div class="bottom-form">
      <div class="download">
        <el-button type="primary">导出报表</el-button>
      </div>
      <div class="down-record">
        查看导出记录
      </div>
    </div>

    <div class="top-form">
      <el-select @change="handleShopChange" v-model="ruleForm.area" class="m-2" placeholder="统计范围" size="large">
        <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
      </el-select>

      <el-select @change="handleTimeTypeChange" v-model="ruleForm.timeType" class="m-2" placeholder="时间类别" size="large">
        <el-option v-for="item in timeType" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <template v-if="ruleForm.timeType == 0">
        <div>
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="date" placeholder="选择日期"
            :size="size" />
        </div>
      </template>
      <template v-if="ruleForm.timeType == 1">
        <div>
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="month" placeholder="选择月份" />
        </div>
      </template>
      <template v-if="ruleForm.timeType == 2">
        <div>
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="daterange" range-separator="到"
            start-placeholder="开始时间" end-placeholder="结束时间" />
        </div>
      </template>
      <!-- <div>
        <el-input @change="handleSearchChange" class="search" clearable prefix-icon="search" v-model="ruleForm.keyword"
          placeholder="输入员工姓名" />
      </div> -->

      <div class="search-wrap">
        <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { getBelongShop } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import { timeType } from '../../config'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {

    const ruleForm = reactive({
      timeType: 0,
      date: "",
      area: "0",
      keyword: ""
    })

    const handleDateChange = value => {
      let type = ruleForm.timeType
      // console.log(type);
      if (type != 2) {
        let dateValue
        if (value == null) {
          dateValue = new Date()
        } else {
          dateValue = value
        }

        let date = dayjs(dateValue).format('YYYY-MM-DD')
        let yearmonth = dayjs(dateValue).format('YYYY-MM')
        let dayTotal = dayjs(date).daysInMonth()

        if (type == 0) {
          cache.setCache('tichengStart', date + ' 00:00:00')
          cache.setCache('tichengEnd', date + ' 23:59:59')
        } else if (type == 1) {
          cache.setCache('tichengStart', date + ' 00:00:00')
          cache.setCache('tichengEnd', yearmonth + '-' + dayTotal + ' 23:59:59')
        }
      } else {
        if (value == null) {
          let yearmonth = dayjs().format('YYYY-MM')
          let dayTotal = dayjs().daysInMonth()
          cache.setCache('tichengStart', yearmonth + '-01 00:00:00')
          cache.setCache('tichengEnd', yearmonth + '-' + dayTotal + ' 23:59:59')
        } else {
          let date1 = dayjs(value[0]).format('YYYY-MM-DD')
          let date2 = dayjs(value[1]).format('YYYY-MM-DD')
          cache.setCache('tichengStart', date1 + ' 00:00:00')
          cache.setCache('tichengEnd', date2 + ' 23:59:59')
        }
      }
      emitsfunc()
    }

    const shopList = ref([])
    const handleShopChange = shopid => {
      cache.setCache('tichengShopid', shopid)
      emitsfunc()
    }

    const emitsfunc = () => {
      emitter.emit('refreshtichengDatetime1')
      emitter.emit('refreshtichengDatetime2')
      emitter.emit('refreshtichengDatetime3')
      emitter.emit('refreshtichengDatetime4')
      emitter.emit('refreshtichengDatetime5')
      emitter.emit('refreshtichengDatetime6')
      emitter.emit('refreshtichengDatetime7')
    }

    const handleTimeTypeChange = value => {
      cache.setCache('tichengTimeType', value)
      ruleForm.date = ''
      let date = dayjs().format('YYYY-MM-DD')
      let yearmonth = dayjs().format('YYYY-MM')
      let dayTotal = dayjs(date).daysInMonth()
      if (value == 0) {
        cache.setCache('tichengStart', date + ' 00:00:00')
        cache.setCache('tichengEnd', date + ' 23:59:59')
      } else {
        cache.setCache('tichengStart', yearmonth + '-01 00:00:00')
        cache.setCache('tichengEnd', yearmonth + '-' + dayTotal + ' 23:59:59')
      }
      emitsfunc()
    }

    const initPage = async () => {
      const res = await getBelongShop(COMPANY_ID)
      let list = res.data.list
      list.unshift({
        shopName: "总部",
        shopId: "0"
      })

      shopList.value = res.data.list
      let tichengShopid = cache.getCache('tichengShopid')
      if (String(tichengShopid).length == 0) {
        cache.setCache('tichengShopid', '0')
      } else {
        ruleForm.area = tichengShopid
      }
      cache.setCache('tichengTimeType', ruleForm.timeType)
      let tichengTimeType = cache.getCache('tichengTimeType')
      if (tichengTimeType != undefined) {
        ruleForm.timeType = tichengTimeType
      }

      let start = cache.getCache('tichengStart')
      let end = cache.getCache('tichengEnd')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      let yearmonth = dayjs(new Date()).format('YYYY-MM')

      if (tichengTimeType == 0 && start != undefined) {
        ruleForm.date = dayjs(start).format('YYYY-MM-DD')
      } else if (tichengTimeType == 0 && start == undefined) {
        ruleForm.date = date
      } else if (tichengTimeType == 1 && start != undefined) {
        ruleForm.date = dayjs(start).format('YYYY-MM')
      } else if (tichengTimeType == 1 && start == undefined) {
        ruleForm.date = yearmonth
      } else if (tichengTimeType == 2 && start != undefined) {
        let _start = dayjs(start).format('YYYY-MM-DD')
        let _end = dayjs(end).format('YYYY-MM-DD')
        ruleForm.date = [_start, _end]
      }
    }
    initPage()


    return {
      ruleForm,
      shopList,
      handleShopChange,
      timeType,
      handleDateChange,
      handleTimeTypeChange,
      emitsfunc
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:deep(.el-input) {
    margin-right: 10px;
  }

  .bottom-form {
    display: flex;
    align-items: center;

    .down-record {
      margin-left: 10px;
      font-size: 13px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }
  }

  .top-form {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &:deep(.el-select) {
      margin-right: 10px;
    }

    .search-wrap {
      margin-left: 10px;
    }

    .download {
      margin-right: 10px;
    }

  }

}
</style>




