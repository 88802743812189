export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩统计',
    path: ''
  }
]

export const timeType=[
  {
    label:'日报',
    value:0,
  }, {
    label:'月报',
    value:1,
  }, {
    label:'自定义',
    value:2,
  }
]
