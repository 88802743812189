<template>
  <div>
    <page-content :dataList="dataList" class="page-content" :contentTableConfig="contentTableConfig"
      @tableCellClick="handleTableCellClick">
      <template #handler>
        <div>
          查看详情
        </div>
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {


    return {
      contentTableConfig
    }

  }
})
</script>

<style scoped lang="less">
</style>




